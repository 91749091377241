/** @jsx jsx */
import { jsx } from 'theme-ui';
import Member from '../Common/Member';

const Staff = () => {
  const members = [
    {
      name: 'Dr. Jeffrey Keenan',
      position: 'NEDC President',
      description: `<a href="https://www.youtube.com/watch?v=49YoE6I51rE" >Dr. Jeffrey Keenan</a>, a highly respected infertility and reproductive medicine specialist, leads the NEDC team. He is the center’s president and medical director. He is board certified in Obstetrics and Gynecology, as well as Reproductive Endocrinology and Infertility.<br/><br/>In practice since 1990, Dr. Keenan has performed innumerable fertility procedures with outstanding success rates. <a href="https://www.youtube.com/watch?v=49YoE6I51rE">To get to know Dr. Keenan better through an in-depth conversation with master interviewer Hallerin Hilton Hill, click here.</a>`,
      image: '/images/staff/Dr_Keenan.png',
    },
    {
      name: 'Dr. Swarna Reddy, MBBS',
      position: 'Embryologist',
      description: 'Dr. Swarna Reddy, MBBS., is a highly qualified medical professional with a strong background in clinical medicine and a focus on Embryology. She received a Bachelor of Medicine Bachelor of Surgery (MBBS) from MR Medical College. Currently, she serves as a Clinical Embryologist for the NEDC in Knoxville, TN,  where she is committed to assisting couples in their fertility goals. With her unique blend of medical knowledge and technical expertise, she brings a high level of precision and care to her work in the embryology laboratory. <br/><br/> A former resident of Atlanta, Georgia, her interests outside of medicine include traveling and spending time with her family and friends. She is married with two children and pet dog Snowball.',
      image: '/images/staff/Swarna.jpg',
    },
    {
      name: 'Mark Mellinger',
      position: 'Marketing and Development Director',
      description:
        'Mark handles all marketing and development functions for the NEDC. In addition to his part-time role here, he also serves in a full-time capacity as Director of Advancement for Haggai International. Mark has worked as a writer, anchor, podcast/radio show host, and interviewer for many of the nation’s leading Christian journalism outlets, including The Gospel Coalition, WORLD News Group, Focus on the Family, and Bott Radio Network.',
      image: '/images/staff/Mark_Mellinger.png',
    },
    {
      name: 'Greg Madron',
      position: 'Office Manager',
      description:
        'Greg graduated from Ivy Tech Community College with an associate’s degree in medical assisting in 2004. He then moved on to Indiana University East in Richmond, Indiana to complete his bachelor’s degree in general studies. Greg has worked as both a Medical Assistant as well as a Practice Manager for both Primary and Urgent Care offices. Greg and his wife Charlotte have been married for almost five years.',
      image: '/images/staff/Greg_Madron.png',
    },
    {
      name: 'Tangy Blair',
      position: 'Recipient Coordinator',
      description:
        'Tangy helps NEDC families through the embryo adoption process, from filling out the paperwork to embryo selection. Tangy has worked in the medical field for the better part of two decades. She’s dedicated to personalized, compassionate patient care. Away from the office, Tangy enjoys decorating her home, is always ready for a good DIY challenge, and loves spending time with her daughter and family.',
      image: '/images/staff/Tangy_Blair.jpg',
    },
    {
      name: 'Lynda McCollum',
      position: 'Donor Nurse Coordinator',
      description: 'While working 23 years as an oncology nurse, Lynda developed a deep appreciation for life and quality of life.  For more than a decade, she worked with NEDC recipients as an IVF Nurse to help build their families.  Her appreciation for life, at any stage, was only enhanced during this time. Lynda now works with individuals donating their precious embryos. She considers herself extremely blessed to continue to be a part of the NEDC.',
      image: '/images/staff/Lynda_McCollum.png',
    },
    {
      name: 'Mary Montgomery',
      position: 'Clinic Liaison',
      description: 'Mary started her medical support journey in Woodburn, OR where she grew up. Since 2006 she’s worked closely with patients and physicians in both clinical and administrative roles, spending most of that time in Women’s Health and Primary Care; however, she has also gained experience in Gastroenterology and Gynecologic Oncology.  She relocated to Tennessee in 2016 after meeting her husband, James. When she’s not at the NEDC, Mary enjoys teaching yoga, reading, dancing, fishing, disc golf, and spending time with James and their 2 fuzzy family members, a golden retriever-black lab mix and a tuxedo kitty.',
      image: '/images/staff/Mary_Montgomery.jpg',
    }
   ]
   const members_affliate = [
    {
      name: 'Lisa Dorman',
      position: 'Lab Director',
      description: 'isa Dorman, PhD, is originally from Arkansas but has now lived in Central Mississippi for over 30 years. She is a graduate of the University of Mississippi Medical Center, where she received her MS, and PhD. She is board certified in Human Clinical Embryology and Andrology and is recognized as a High Complexity Laboratory Director by the American Board of Bioanalysis. In addition to her board certification as a HCLD, Lisa is a registered Clinical Laboratory Scientist certified by ASCP. In her free time, Lisa is up early to take care of her many rescue pets located at home and around Central Mississippi.',
      image: '/images/staff/Lisa_Dorman.jpg',
    },
    {
      name: 'Lisa Rochelle',
      position: 'Clinic Nurse',
      description: 'Lisa Rochelle, RN, has over 15 years of experience in the field of nursing. She received her nursing degree from ETSU in 2003 and since then has worked in the neonatal intensive care unit, surgery, and pre- and postoperative care, as well as at her children’s school as the school nurse. Lisa was raised in Knoxville and has spent most of her life in East Tennessee, where she now lives with her two children. They keep her very busy with lacrosse, football, and gymnastics! In her spare time, Lisa enjoys hiking, gardening, reading, and watching the Tennessee Volunteers play football.',
      image: '/images/staff/LisaRochelle.jpg',
    }
  ];
  return (
    <section
      sx={{
        bg: '#61A19E',
        backgroundImage: `url('/images/background2.png')`,
      }}
    >
      <div
        className="content"
        sx={{
          px: '2rem',
          py: '3rem',
        }}
      >
        <div>
          <h1
            sx={{
              variant: 'text.xl',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            Our Staff
          </h1>
        </div>
        <div sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', my: '3rem' }}>
          {members.map((member, index) => (
            <Member key={index} data={{ ...member, index }} mode="dark" />
          ))}
          <p sx={{ color: "white", font: "bold", fontSize: "24px" }}>Clinical Team from Our Medical Affiliate, Southeastern Fertility Center</p>
          {members_affliate.map((member, index) => (
            <Member key={index} data={{ ...member, index }} mode="dark" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Staff;
